import React from "react";
import { data } from "./data";
import CountUp from "react-countup";
import { Col, Row } from "react-bootstrap";
// import { GiStarsStack } from "react-icons/gi";
import { wheat, videoo } from "../../../../../assets";
import "./backVideo.css";

export const BackVideo = () => {
	return (
		<div className="video-container">
			<video controls disablePictureInPicture autoPlay muted loop controlsList="nodownload" id="myVideo">
				<source src={videoo} type="video/mp4" />
			</video>
			<div className="content" style={{
				"display": "flex",
				"flexDirection": "column",
			}}>
				<Row className="row1">
					{data.map((value, index) => {
						return (
							<Col lg={3} className="column" key={index}>
							
								<div
									className="text-center cardBox"
									style={{
										backgroundColor: "transparent",
									}}
								>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
									>
										<img
											src={wheat}
											style={{
												maxWidth: "min(200px,80vw)",
												position: "absolute",
											}}
											alt="wheat"
										/>
										<div
											style={{
												display: "flex",
											}}
										>
											<h1>#&nbsp;</h1>
											<h1>
												<CountUp className="rank" end={value.Ranking} duration={4} />
											</h1>
										</div>
									</div>
									<p className="rankText text-center">{value.Heading}</p>
								</div>
							</Col>
						);
					})}
				</Row>
				
				<div className="text-center mt-4 text-white ">
					<h2 style={{ fontFamily: "serif", fontStyle: "italic"}}>
						वसुधैव कुटुम्बकम्
					</h2>
					<p style={{ fontSize: "1.2rem" }}>
						"The world is one family"
					</p>
				</div>
			</div>
		
		</div>
	);
};
